import React from 'react';
import PropTypes from 'prop-types';
import {COLORS} from '../styles/constants';

interface FooterProps {
  siteTitle: string;
}
const Footer = ({siteTitle}: FooterProps) => (
  <footer
    style={{
      padding: '1rem',
      backgroundColor: COLORS.lightGray,
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100vw',
    }}>
    <div
      style={{
        display: 'flex',
        // alignItems: "center",
        justifyContent: 'space-between',
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 240px))',
        // padding: "1rem 2rem",
        fontSize: '.85rem',
      }}>
      <div>
        <a
          style={{
            textDecoration: 'none',
            color: COLORS.gray,
            fontWeight: 700,
          }}
          href="mailto:team@celadin.com">
          Contact Us
        </a>
      </div>
      <div>
        <a
          style={{
            textDecoration: 'none',
            color: COLORS.gray,
            fontWeight: 700,
          }}
          href="https://celadin.com">
          © {new Date().getFullYear()}
          {` Celadin Ltd`}
        </a>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;
