import React from "react"
//@ts-ignore
import headerImage from "../images/header2.jpg"

const PageBackground = () => {
    return (
        <div
        style={{
          backgroundImage: `url(${headerImage})`,
          backgroundAttachment: 'fixed',
          position: "absolute",
          top: 0,
          zIndex: -5,
          height: "100vh",
          width: "100vw",
          opacity: 1,
        }}
      />
    )
}
export default PageBackground;
