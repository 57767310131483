import React from 'react';
import {Link, navigate} from 'gatsby';
import {COLORS} from '../styles/constants';
import {useUser, useIsLoggedIn} from '../containers/user-provider';
import {signOut} from '../services/firebase';

const textStyle = {
  textDecoration: 'none',
  color: COLORS.gray,
  fontWeight: 700,
};

export default function NavBar() {
  const {displayName} = useUser();
  const isLoggedIn = useIsLoggedIn();
  const greetingMessage = isLoggedIn
    ? `Hello ${displayName}`
    : 'You are not logged in';

  const homeLink = (
    <Link style={textStyle} to="/">
      Home
    </Link>
  );

  const logoutLink = (
    <a
      style={textStyle}
      href="/"
      onClick={async event => {
        event.preventDefault();
        await signOut();
        // navigate(`/app/login`);
      }}>
      Logout
    </a>
  );

  const profileLink = (
    <Link style={textStyle} to="/app/profile">
      Profile
    </Link>
  );
  /* login link is actually a link to profile.
     this gives us a destination page for successful login.
     profile is a private route - so we are forced into the login routine.
     Once resolved - we show the profile
  */
  const loginLink = (
    <Link style={textStyle} to="/app/profile">
      Login
    </Link>
  );
  const separator = ' | ';
  const links = isLoggedIn ? (
    <>
      {homeLink}
      {separator}
      {profileLink}
      {separator}
      {logoutLink}
    </>
  ) : (
    <>
      {homeLink}
      {separator}
      {loginLink}
    </>
  );

  return (
    <header
      style={{
        padding: '1rem',
        backgroundColor: COLORS.lightGray,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
      }}>
      <div
        style={{
          display: 'flex',
          // alignItems: "center",
          justifyContent: 'space-between',
          gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 240px))',
          // padding: "1rem 2rem",
          fontSize: '.85rem',
        }}>
        <div>
          <span style={textStyle}>{greetingMessage}</span>
        </div>
        <div>
          <nav>{links}</nav>
        </div>
      </div>
    </header>
  );
}
