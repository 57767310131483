/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"
import PageBackground from "./page-background"
import CookieConsent from "react-cookie-consent"

import Footer from "./footer"
import "../styles/default.css"
import { ReactNode } from "react"
import NavBar from "./nav-bar"
interface LayoutProps {
  children: ReactNode[] | ReactNode;
}
const Layout = ({ children }: LayoutProps) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          enableDeclineButton={true}
          hideOnAccept={true}
          cookieName="gatsby-gdpr-google-analytics"
          sameSite="none"
          contentStyle={{ fontSize: 14 }}
        >
          We use tools. such as cookies, to enable essential services and
          functionality on our site and collect data on how visitors interact
          with our site, products and services. By clicking Accept or continuing
          to use this site, you agree to our use of these tools for advertising
          and analytics. <Link to="/privacy">Learn More</Link>
        </CookieConsent>

        <PageBackground />
        <NavBar />
        <div
          style={{
            padding: "0 1rem",
            paddingTop: 70
          }}
        >
          <main>{children}</main>
        </div>
        <Footer siteTitle={data.site.siteMetadata.title} />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
